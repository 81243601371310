/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CAPITAL_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import {
  currencyFormatter,
  filterOnlyChildMaterial,
  getFactoryCapitalCostInDay,
} from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api, config } from '@/configs';

import MultiUnitProdOrMatBox from '../../Rendering/MultiUnitProdOrMatBox';

const CapitalCostTDABCArtifact = ({
  previousLotList,
  place,
  currentLot,
  enableElement,
  isCurrentAsProductLot,
}) => {
  const allowMaterial = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  console.log(`Allow Material For Place ${place?.name}`, allowMaterial);
  const allowChildMaterial = filterOnlyChildMaterial({
    allowCategory: place?.allow_categories,
    currentLotMaterial: currentLot?.material,
    filterLotOnPlace: allowMaterial,
  });
  const [factoryCapitalCost, setFactoryCapitalCost] = useState(0);
  const information = useSelector((state) => state.information);
  const hourPerDay = information?.setting?.pams?.cc_working_hour_per_day;
  const minutePerDay = hourPerDay * 60;
  const costPerMinute = factoryCapitalCost / minutePerDay;

  const currentDate = isCurrentAsProductLot
    ? currentLot?.inventory_date
    : currentLot?.receipt_date;

  const getCapitalCost = async () => {
    try {
      const tempFactoryCapitalCost = await getFactoryCapitalCostInDay({
        currentDate,
        isFrontend: true,
        axios: api,
        maxFetchSize: config.maxFetchSize,
        information,
        enableElement,
      });
      setFactoryCapitalCost(tempFactoryCapitalCost);
    } catch (error) {
      console.error('Cannot Get Factory Capital Cost', error);
    }
  };

  useEffect(() => {
    getCapitalCost();

    return () => {};
  }, []);

  console.log('Factory Capital Cost', factoryCapitalCost);

  const calculateCostPerMaerial = (row) => {
    const minuteUse = (row?.material?.average_unit_time || 0) * row?.quantity;

    const costPerOneMan = costPerMinute * minuteUse;
    return costPerOneMan;
  };

  return (
    <div>
      <div className="flex justify-between my-2">
        <div className="font-semibold">
          ต้นทุนการลงทุน: {CAPITAL_COST_ESTIMATION_METHOD.TDABC.description}
        </div>
        <Link to={'/cem/factory-capital-cost/'}>
          <Button size="small" variant="outlined" color="warning">
            แก้ไขค่าทรัพยากร
          </Button>
        </Link>
      </div>
      <div className="my-2 flex flex-wrap">
        <div className="w-full md:w-1/2">
          <div>ต้นทุนรวมของการลงทุนในคลัง</div>
          <div>
            <span className="text-lg font-semibold">
              {currencyFormatter.format(factoryCapitalCost)}
            </span>{' '}
            บาท
          </div>
        </div>{' '}
        <div className="w-full md:w-1/2">
          <div>เฉลี่ยต่อนาที</div>
          <div>
            <span className="text-lg font-semibold">
              {currencyFormatter.format(costPerMinute)}
            </span>{' '}
            บาท
          </div>
        </div>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขอ้างอิงล็อต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    เวลาโดยประมาณ
                    <br />
                    ต่อหน่วย
                  </div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ปรืมาณ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รวมเวลา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ต้นทุนรวม</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(allowChildMaterial) ? (
                _.map(allowChildMaterial, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>{row?.running_number}</TableCell>
                    <TableCell>{row?.material?.name}</TableCell>
                    <TableCell>
                      {row?.material?.average_unit_time || '-'} นาที
                    </TableCell>{' '}
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        noWrap
                        quantity={row?.quantity}
                      />
                    </TableCell>{' '}
                    <TableCell>
                      {currencyFormatter.format(
                        row?.material?.average_unit_time * row?.quantity,
                      )}{' '}
                      นาที
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(calculateCostPerMaerial(row))}
                      บาท
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              {!_.isEmpty(allowChildMaterial) && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center font-bold">รวม</div>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <div className="font-bold">
                      {currencyFormatter.format(
                        _.sumBy(allowChildMaterial, (o) =>
                          calculateCostPerMaerial(o),
                        ),
                      )}{' '}
                      บาท
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CapitalCostTDABCArtifact;

CapitalCostTDABCArtifact.propTypes = {
  previousLotList: PropTypes.arrayOf(PropTypes.object),
  place: PropTypes.object,
  currentLot: PropTypes.object,
  enableElement: PropTypes.object,
  isCurrentAsProductLot: PropTypes.bool,
};
